@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.Login_section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
  background: url(../public/Images/redBg.png);
  /* background-size: cover; */
}

.Login_section .login_from_area {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0px;
  border-radius: 15px;
  box-shadow: 5px 5px 15px 0 #7a7a7ab3;
}

.Login_section .login_from_area .Login_form_sideImg img {
  height: 70vh;
  /* height: 600px; */
  width: 100%;
  object-fit: contain;
  padding: 20px;
  border-radius: 15px 0 0 15px;
}

.Login_section .login_from_area .Login_form_sideImg ul,
.Login_section .login_from_area .Login_form_sideImg ul li {
  padding: 0 !important;
  margin: 0 !important;
}

.Login_section .login_from_area .login_from_sideRight {
  padding: 30px;
}

.Login_section .login_from_area .login_from_Logo_area {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.Login_section .login_from_area .login_from_Logo_area img {
  width: 100%;
  height: 90px;
  object-fit: contain;
}

.Login_section .login_from_area .Login_from_Forgetpassword_Link {
  text-align: end;
}

.Login_section .login_from_area .Login_from_Forgetpassword_Link a {
  color: #252525;
  text-decoration: none;
}

.header_section {
  background-image: #1976d2 !important;
  /* background-image: linear-gradient(90deg, #00c6d6, #1976d2) !important; */
  color: #fff;
}

.header_section .Dashborad_logo_img_moblie {
  display: none;
}

.header_section .Dashborad_logo_img {
  display: block;
  margin-right: 20px;
}

.header_section .Dashborad_menu_area_Desktop button {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.Dashborad_HeroBanner_section {
  background-color: #eaf4ff;
  min-height: 20vh;
  background-size: cover;
  background-position: 50% 50%;
}

.Dashborad_HeroBanner_section .Dashborad_welcome_heading {
  padding-top: 50px;
  font-family: 'Montserrat', sans-serif !important;
}

.Dashborad_HeroBanner_section .dashborad_search_Area {
  width: 600px;
  margin: 50px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 3px 4px 10px 0px #55555561;
  border-radius: 50px;
}

.Dashborad_HeroBanner_section .Dashborad_welcome_heading h4 {
  font-family: 'Montserrat', sans-serif !important;
  font-family: 500;
}

.Dashborad_HeroBanner_section .Dashborad_welcome_heading h3 {
  margin-bottom: 15px;
  color: #1976d2;
  font-weight: 900;
  /* font-family: 'Montserrat', sans-serif !important; */
}

.Dashborad_HeroBanner_section .Dashborad_welcome_heading p {
  font-size: 17px;
  color: #5b5b5b;
  font-family: 'Montserrat', sans-serif !important;
}

.App_Store_section {
  margin: 30px 0;
  padding: 20px 0;
}

.App_Store_section .App_Store_form_area .App_Store_form_items {
  max-width: 300px !important;
  margin: 0 auto 20px;
}

.App_Store_section .App_Store_form_area .App_Store_form_items .css-46bh2p-MuiCardContent-root {
  padding: 15px 10px;
}

.App_Store_section .App_Store_form_area .App_Store_form_items h5 {
  font-size: 22px;
  font-weight: 500;
}

.App_Store_section .App_Store_form_area .App_Store_form_items p {
  font-size: 14px;
  font-weight: 400;
}

.App_Store_section .Eplod_3_Form_img {
  object-fit: contain;
  background-color: #eaf4ff;
  height: 130px;
}

.App_Store_section Card:hover .Eplod_3_Form_img {
  transform: scale(0.1);
}

.App_Store_section.Applications_own_Section {
  background: none;
}

.Eplod_3_Form_modal_section .MuiPaper-root {
  margin: 15px;
}

.Eplod_3_Form_modal_section .MuiPaper-root .Eplod_3_Form_modal_button {
  padding: 0 24px 24px;
}

.Applications_own_Section {
  margin: 30px 0;
  padding: 50px 0;
  background-color: #eaf4ff;
}

.Applications_own_Section .Applications_own_Heading h4 {
  margin-bottom: 15px;
  color: #1976d2;
  font-weight: 900;
}

.Applications_own_Section .Applications_own_Heading p {
  font-size: 17px;
  color: #5b5b5b;
  font-family: 'Montserrat', sans-serif !important;
}

.Profile_Banner_section {
  width: 100%;
  height: 300px;
  background-image: url(../public/Images/Profile_banner.jpg);
  background-position: center;
  background-size: cover;
}

.Profile_content_section {
  position: relative;
  height: 120px;
  width: 100%;
  /* background-color: #eaf4ff; */
  box-shadow: 0 5px 10px #0a0a0a13;
}

.Profile_content_section .Profile_content_area {
  display: flex;
  align-items: end;
  position: absolute;
  bottom: 20px;
}

.Profile_content_section .Profile_content_area .Profile_content_img {
  width: 110px;
  height: 110px;
  font-size: 50px;
  font-weight: 700;
  border-radius: 15px;
  border: 4px solid #fff;
  object-fit: cover;
  margin-right: 15px;
  box-shadow: 3px 3px 10px #0a0a0a61;
}

.Profile_content_section .Profile_content_area .Profile_content_Detail {
  margin-bottom: 15px;
}

.Profile_content_section .Profile_content_area .Profile_content_Detail h5 {
  font-size: 30px;
  font-weight: 900;
  text-transform: capitalize;
  color: #1976d2;
  font-family: 'Montserrat', sans-serif !important;
}

.Profile_content_section .Profile_content_area .Profile_content_Detail p {
  font-size: 16px;
  font-size: 600;
  color: #5b5b5b;
  text-transform: lowercase;
  font-family: 'Montserrat', sans-serif !important;
}

.Profile_update_from_section {
  padding: 50px 0;
  /* background-image: url(../public/Images/); */
}

.Profile_update_from_section .Profile_update_from_heading {
  padding-bottom: 5px;
  margin-bottom: 30px;
  padding-left: 10px;
  border-bottom: 2px solid #1976d2;
  color: #1976d2;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif !important;
}

.Profile_update_from_section .Profile_update_tabs_area .Profile_Update_tabs_button {
  font-size: 18px;
  font-weight: 500;
  align-items: start;
  text-transform: capitalize;
}

.Profile_update_from_section .Profile_tabsContent_from_Area .MuiBox-root.css-19kzrtu {
  padding: 0px 25px;
}

.MuiButtonBase-root.MuiCardActionArea-root.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
  height: 100%;
}

@media (max-width: 1100px) {
  .Dashborad_HeroBanner_section .Dashborad_welcome_heading {
    padding-top: 30px;
  }
}

@media (max-width: 899px) {
  .Login_section .login_from_area .login_from_sideRight {
    padding: 20px 15px;
  }

  .Login_section .login_from_area {
    margin: 30px 0;
  }

  .Login_section .login_from_area .Login_form_sideImg img {
    height: 400px;
    border-radius: 15px 15px 0 0;
  }

  .Dashborad_logo_img_moblie {
    display: flex;
    margin-right: 2;
  }

  .Dashborad_logo_img {
    display: none;
  }

  .Profile_Banner_section {
    width: 100%;
    height: 220px;
  }
}

@media (max-width: 767px) {
  .Login_section .login_from_area .login_from_sideRight {
    padding: 20px 15px;
  }

  .Login_section .login_from_area .Login_form_sideImg img {
    height: 250px;
    border-radius: 15px 15px 0 0;
  }

  .Dashborad_HeroBanner_section .dashborad_search_Area {
    width: 100%;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading h4 {
    font-size: 25px;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading h3 {
    font-size: 35px;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading p {
    font-size: 18px;
  }

  .Applications_own_Section .Applications_own_Heading h4 {
    font-size: 28px;
  }

  .Applications_own_Section .Applications_own_Heading p {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .Dashborad_HeroBanner_section {
    background-position: 88%, 50%;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading {
    padding-top: 20px;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading h4 {
    font-size: 19px;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .Dashborad_HeroBanner_section .Dashborad_welcome_heading p {
    font-size: 12px;
    line-height: 1.6;
  }

  .Applications_own_Section .Applications_own_Heading h4 {
    font-size: 22px;
  }

  .Applications_own_Section .Applications_own_Heading p {
    font-size: 12px;
  }

  .Dashborad_HeroBanner_section .dashborad_search_Area {
    margin-top: 30px;
  }

  .Eplod_3_Form_modal_section .MuiPaper-root .Eplod_3_Form_modal_content {
    padding: 0 15px 25px;
  }

  .Profile_content_section {
    height: 90px;
  }

  .Profile_content_section .Profile_content_area .Profile_content_img {
    width: 80px;
    height: 80px;
  }

  .Profile_content_section .Profile_content_area .Profile_content_Detail {
    margin-bottom: 5px;
  }

  .Profile_content_section .Profile_content_area .Profile_content_Detail h5 {
    font-size: 22px;
  }

  .Profile_content_section .Profile_content_area .Profile_content_Detail p {
    font-size: 14px;
  }

  .Profile_content_section .Profile_content_area {
    bottom: 15px;
  }

  .Profile_Banner_section {
    width: 100%;
    height: 150px;
  }

  .Profile_update_from_section .Profile_tabsContent_from_Area .MuiBox-root.css-19kzrtu {
    padding: 25px 0;
  }
}

.dashboard_FeaturedSection_heading {
  font-size: 30px;
  font-weight: 900;
  text-transform: capitalize;
  color: #1976d2;
  font-family: 'Montserrat', sans-serif !important;
}

/* login  error css  */
.spanerror {
  color: red !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: 0;
  max-height: 200px;
}

.css-ypiqx9-MuiDialogContent-root,
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow-y: visible !important;
}